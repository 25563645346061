import { faLinkedinIn, faXTwitter } from '@fortawesome/free-brands-svg-icons';
import {
  faArrowUpArrowDown,
  faDeleteLeft,
  faGear,
  faNewspaper,
  faPlus,
  faTrashCan,
  faHeart as farHeart,
} from '@fortawesome/pro-regular-svg-icons';
import {
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faArrowUp,
  faArrowUpLeft,
  faArrowsRotate,
  faBars,
  faBell,
  faBook,
  faBoxOpen,
  faBuilding,
  faCaretDown,
  faCaretUp,
  faCartShopping,
  faCheck,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircleInfo,
  faClipboard,
  faComment,
  faCreditCard,
  faCubes,
  faDiamond,
  faDownload,
  faEllipsisVertical,
  faEnvelope,
  faExclamation,
  faEye,
  faEyeSlash,
  faFileInvoiceDollar,
  faFilePdf,
  faFileZipper,
  faFolderGear,
  faGrid,
  faHeart,
  faHome,
  faList,
  faMagnifyingGlass,
  faPencil,
  faPhone,
  faPowerOff,
  faPrint,
  faRotate,
  faRotateLeft,
  faUser,
  faX,
  faXmark,
} from '@fortawesome/pro-solid-svg-icons';
import { classNames } from '@helpers/classNames';
import PropTypes from 'prop-types';
import { iconSize } from './iconConstants';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const baseStyle = 'flex items-center justify-center';

// TODO Refactor switch to something more elegant
function IconSwitch(iconName) {
  switch (iconName) {
    case 'arrow-up':
      return <FontAwesomeIcon icon={faArrowUp} />;
    case 'arrow-up-down':
      return <FontAwesomeIcon icon={faArrowUpArrowDown} />;
    case 'arrow-right':
      return <FontAwesomeIcon icon={faArrowRight} />;
    case 'arrow-down':
      return <FontAwesomeIcon icon={faArrowDown} />;
    case 'arrow-left':
      return <FontAwesomeIcon icon={faArrowLeft} />;
    case 'arrow-up-left':
      return <FontAwesomeIcon icon={faArrowUpLeft} />;
    case 'arrows-rotate':
      return <FontAwesomeIcon icon={faArrowsRotate} />;
    case 'bars':
      return <FontAwesomeIcon icon={faBars} />;
    case 'bell':
      return <FontAwesomeIcon icon={faBell} />;
    case 'book':
      return <FontAwesomeIcon icon={faBook} />;
    case 'building':
      return <FontAwesomeIcon icon={faBuilding} />;
    case 'caret-up':
      return <FontAwesomeIcon icon={faCaretUp} />;
    case 'caret-down':
      return <FontAwesomeIcon icon={faCaretDown} />;
    case 'cart':
      return <FontAwesomeIcon icon={faCartShopping} />;
    case 'check':
      return <FontAwesomeIcon icon={faCheck} />;
    case 'chevron-up':
      return <FontAwesomeIcon icon={faChevronUp} />;
    case 'chevron-right':
      return <FontAwesomeIcon icon={faChevronRight} />;
    case 'chevron-down':
      return <FontAwesomeIcon icon={faChevronDown} />;
    case 'chevron-left':
      return <FontAwesomeIcon icon={faChevronLeft} />;
    case 'cog':
      return <FontAwesomeIcon icon={faGear} />;
    case 'comment':
      return <FontAwesomeIcon icon={faComment} />;
    case 'circle-info':
      return <FontAwesomeIcon icon={faCircleInfo} />;
    case 'clipboard':
      return <FontAwesomeIcon icon={faClipboard} />;
    case 'credit-card':
      return <FontAwesomeIcon icon={faCreditCard} />;
    case 'cube':
      return <FontAwesomeIcon icon={faCubes} />;
    case 'deleteLeft':
      return <FontAwesomeIcon icon={faDeleteLeft} />;
    case 'diamond':
      return <FontAwesomeIcon icon={faDiamond} />;
    case 'download':
      return <FontAwesomeIcon icon={faDownload} />;
    case 'eye':
      return <FontAwesomeIcon icon={faEye} />;
    case 'eye-slash':
      return <FontAwesomeIcon icon={faEyeSlash} />;
    case 'exclamation':
      return <FontAwesomeIcon icon={faExclamation} />;
    case 'file-zipper':
      return <FontAwesomeIcon icon={faFileZipper} />;
    case 'folder-gear':
      return <FontAwesomeIcon icon={faFolderGear} />;
    case 'grid':
      return <FontAwesomeIcon icon={faGrid} />;
    case 'heart':
      return <FontAwesomeIcon icon={faHeart} />;
    case 'heart-outline':
      return <FontAwesomeIcon icon={farHeart} />;
    case 'home':
      return <FontAwesomeIcon icon={faHome} />;
    case 'linkedin':
      return <FontAwesomeIcon icon={faLinkedinIn} />;
    case 'list':
      return <FontAwesomeIcon icon={faList} />;
    case 'mail':
      return <FontAwesomeIcon icon={faEnvelope} />;
    case 'menu':
      return <FontAwesomeIcon icon={faEllipsisVertical} />;
    case 'newspaper':
      return <FontAwesomeIcon icon={faNewspaper} />;
    case 'pencil':
      return <FontAwesomeIcon icon={faPencil} />;
    case 'pdf':
      return <FontAwesomeIcon icon={faFilePdf} />;
    case 'phone':
      return <FontAwesomeIcon icon={faPhone} />;
    case 'power-off':
      return <FontAwesomeIcon icon={faPowerOff} />;
    case 'print':
      return <FontAwesomeIcon icon={faPrint} />;
    case 'product':
      return <FontAwesomeIcon icon={faBoxOpen} />;
    case 'plus':
      return <FontAwesomeIcon icon={faPlus} />;
    case 'rotate':
      return <FontAwesomeIcon icon={faRotate} />;
    case 'rotate-left':
      return <FontAwesomeIcon icon={faRotateLeft} />;
    case 'search':
      return <FontAwesomeIcon icon={faMagnifyingGlass} />;
    case 'subs':
      return <FontAwesomeIcon icon={faFileInvoiceDollar} />;
    case 'twitter':
      return <FontAwesomeIcon icon={faXTwitter} />;
    case 'trash':
      return <FontAwesomeIcon icon={faTrashCan} />;
    case 'user':
      return <FontAwesomeIcon icon={faUser} />;
    case 'x':
      return <FontAwesomeIcon icon={faX} />;
    case 'xmark':
      return <FontAwesomeIcon icon={faXmark} />;
    default:
      return null;
  }
}

const Icon = ({ color = '', iconName = '', size = 'lg', overrideClass = '' }) => {
  const icon = IconSwitch(iconName);

  return <div className={classNames(baseStyle, iconSize[size], color, overrideClass)}>{icon}</div>;
};

// PropTypes that help dev's to know what props are available to render component
Icon.propTypes = {
  color: PropTypes.string,
  iconName: PropTypes.string.isRequired,
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl', '2xl']),
  overrideClass: PropTypes.string,
};

export default Icon;
